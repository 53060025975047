<template>
  <v-row>
    <v-col cols="12">
      <p>
        年次売上目標を設定すると、自動的に月次目標売上が入力されます。
        月次目標は手動で上書き修正ができます。
        クリアを押すと、入力した情報がリセットされます。
      </p>
      <p class="mb-0">
        初期入力時には年次目標をベースに、下記の条件を適用し、参考値を自動出力しています。
      </p>
      <ul class="ml-4 mb-4">
        <li>3月、4月→20%アップ</li>
        <li>12月→30%アップ</li>
        <li>2月、8月→20%ダウン</li>
      </ul>
      <p class="mb-0">
        ＜目標額はいくらが妥当？＞
      </p>
      <p>
        事業年度目標の設定はCP事務局が設定サポートを行うことができますので
        <router-link to="/contact">お問い合わせページ</router-link>
        から、または
        <a href="https://lin.ee/qmYLS7j" target="_blank">
          ラインの公式アカウント
        </a>
        よりお問い合わせください。
        CP事務局はお店の売上目標到達をサポートいたします！
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GoalsSettingModal"
};
</script>

<style scoped></style>
